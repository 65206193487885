<template>
  <div class="content">
    <!-- 菜单 -->
    <div>
      <a-menu style="width: 256px;height:100%" mode="inline" :openKeys="openKeys" v-model:selectedKeys="selectedKeys">
        <template #title>模板1</template>
        <a-menu-item v-for="(item,index) in modMenu" :key="item.id" @click="choose(item,index)">
          <UnorderedListOutlined /> {{ item.menuName}}
        </a-menu-item>
      </a-menu>
    </div>

    <!-- 预览图 -->
    <div class="preview">
      <div id="headImg">
        <a target="_blank" :href="head.jump"><img :style="{width:'100%',height:head.height + 'px',marginBottom:head.marginBottom + 'px',marginTop:head.marginTop + 'px'}" :src="head.imgUrl" /></a>
      </div>
      <!-- 轮播图 -->
      <div id="bannerImg" style="position:relative;margin-bottom:30px;width:100%;">
        <a-carousel arrow :autoplay="true" :autoplaySpeed="banner.interval">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; zindex: 1">
              <left-circle-outlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <right-circle-outlined />
            </div>
          </template>

          <template v-for="(item,index) in bannerList" :key="index">
            <a target="_blank" :href="item.jump === '' ? '#' : item.jump"><img :style="{width:'100%',height:'100%',height:banner.height + 'px',marginTop:banner.marginTop + 'px',marginBottom:banner.marginBottom + 'px'}" :src="item.url" /></a>
          </template>
        </a-carousel>
      </div>
      <!-- 报价标题 -->
      <div id="arrowImg" class="arrow" :style="{width:arrow.width + 'px',height:arrow.height + 'px',marginBottom:arrow.marginBottom + 'px',marginTop:arrow.marginTop + 'px'}">
        <a target="_blank" :href="arrow.jump === '' ? '#' : arrow.jump">
          <img :style="{height:'100%',width:'100%'}" :src="arrow.url" />
        </a>
      </div>
      <!-- 主题内容 -->
      <div class="container-content">
        <p style="display:flex;justify-content: center;"> <span>————</span> 实时运车：报价<span style="color:#0077b8;font-weight: bold;">2000</span>元 <span>————</span> </p>
        <div class="record">
          <div>起点：四川省成都市</div>
          <div>终点：山东省烟台市</div>
          <div style="display:flex">时效：<span style="color:#0077b8;font-weight: bold;flex:1;text-align:center">9</span>天</div>
          <div style="display:flex">保额：<span style="color:#0077b8;font-weight: bold;flex:1;text-align:center">5000</span>万元</div>
        </div>
        <!-- 第一表单 -->
        <a-form ref="formRef" :model="form">
          <a-form-item label="">
            <a-input v-model="form.startAddress" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.endAddress" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.phone" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.verification" />
          </a-form-item>
          <a-form-item label="">
            <div id="formButton">
              <div v-if="formEdit.type === 1" class="btn" :style="{width:'90%',marginLeft:'5%',height:formEdit.height + 'px',marginTop:formEdit.marginTop + 'px',marginBottom:formEdit.marginBottom + 'px',background:`url(${formEdit.url}) no-repeat`,backgroundSize:'100% 100%',fontSize:'25px',color:'#fff',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}">
                {{formEdit.btnText}}
              </div>
              <a-button v-else type="primary" size="large" show-word-limit class="btn" :style="{width:'90%',height:formEdit.height + 'px',marginLeft:'5%',marginTop:formEdit.marginTop + 'px',marginBottom:formEdit.marginBottom + 'px',fontSize:'25px',border:'none',backgroundImage:`linear-gradient(to right,${formEdit.color1},${formEdit.color2})`}">
                <!-- <img src="@/assets/images/first/servucePhone.png" style="width:10%;position:absolute;left:18%;top:23%;height:80%" /> -->
                {{ formEdit.btnText }}
              </a-button>
            </div>
          </a-form-item>
        </a-form>
        <div class="prompt">
          <div :style="{marginTop:prompt + 'px'}">
            <div class="promptText" v-for="(item,index) in promptList" :key="index" style="margin-bottom:10px';text-align:center">{{item}}</div>
          </div>
        </div>

        <div id="img1">
          <a target="_blank" :href="img1.jump"><img :src="img1.url" :style="{width:'100%',height:img1.height + 'px',marginTop:img1.marginTop + 'px',marginBottom:img1.marginBottom + 'px',display:img1.radio}" /></a>
        </div>
        <div id="img2">
          <a target="_blank" :href="img2.jump"><img :src="img2.url" :style="{width:'100%',height:img2.height + 'px',marginTop:img2.marginTop + 'px',marginBottom:img2.marginBottom + 'px',display:img2.radio}" /></a>
        </div>
        <div id="img3">
          <a target="_blank" :href="img3.jump"><img :src="img3.url" :style="{width:'100%',height:img3.height + 'px',marginTop:img3.marginTop + 'px',marginBottom:img3.marginBottom + 'px',display:img3.radio}" /></a>
        </div>

        <div id="customerServiceButton">
          <div v-if="formEdit2.type === 1" class="btn" :style="{width:'90%',marginLeft:'5%',height:formEdit2.height + 'px',marginTop:formEdit2.marginTop + 'px',marginBottom:formEdit2.marginBottom + 'px',background:`url(${formEdit2.url}) no-repeat`,backgroundSize:'100% 100%',fontSize:'25px',color:'#fff',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}">
            {{telStyle.context}}
          </div>
          <a-button v-else type="primary" size="large" class="btn" :style="{padding:'5px',height:formEdit2.height + 'px',marginBottom:'15px',width:'90%',marginLeft:'5%',border:'none',backgroundImage: `linear-gradient(to right, ${formEdit2.color1} , ${formEdit2  .color2})`,fontSize:'25px',position:'relative'}">
            <!-- <img src="@/assets/images/first/servucePhone.png" style="width:10%;position:absolute;left:18%;top:23%;height:80%" /> -->
            {{telStyle.context}}
          </a-button>
        </div>

        <div id="img4">
          <a target="_blank" :href="img4.jump"><img :src="img4.url" :style="{width:'100%',height:img4.height + 'px',marginTop:img4.marginTop + 'px',marginBottom:img4.marginBottom + 'px',display:img4.radio}" /></a>
        </div>
        <div id="img5">
          <a target="_blank" :href="img5.jump"><img :src="img5.url" :style="{width:'100%',height:img5.height + 'px',marginTop:img5.marginTop + 'px',marginBottom:img5.marginBottom + 'px',display:img5.radio}" /></a>
        </div>
        <!-- 底部按钮 -->
        <div class="footer">
          <div id="onLine" :style="{display:inquiryButton.radio}">
            <a-button type="primary" size="large" class="footer-btn" @click="showAlert" :style="{background:'#1C72A1'}">
              <div style="position:relative">
                <img src="@/assets/images/first/inquiry.png" style="width:10%;height:50%;" />
                {{inquiryButton.context}}
              </div>
            </a-button>
          </div>
          <div id="mobile" :style="{display:phoneButton.radio}">
            <a-button type="primary" size="large" class="footer-btn" :style="{background:'#F2B12E'}">
              <img src="@/assets/images/first/servucePhone.png" style="width:15%;position:absolute;left:15%;top:20%;height:80%" />
              {{phoneButton.context}}
            </a-button>
          </div>
        </div>
      </div>
      <!-- 弹出层表单 -->
      <div class="form-alert" v-show="formAlert">
        <a-form ref="formRef" :model="form" class="alert-form">
          <CloseOutlined class="close-btn" @click=" formAlert = false" />
          <a-form-item label="">
            <a-input v-model="form.startAddress" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.endAddress" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.phone" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model="form.verification" />
          </a-form-item>
          <a-form-item label="">
            <div id="inquiryButton">
              <div v-if="formEdit3.type === 1" class="btn" :style="{width:'90%',marginLeft:'5%',height:formEdit3.height + 'px',marginTop:formEdit3.marginTop + 'px',marginBottom:formEdit3.marginBottom + 'px',background:`url(${formEdit3.url}) no-repeat`,backgroundSize:'100% 100%',fontSize:'25px',color:'#fff',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'5px'}">
                {{formAlertText.context}}
              </div>
              <!-- <img v-if="formEdit3.type === 1" class="btn" :src="formEdit3.url" :style="{width:'90%',marginLeft:'5%',height:formEdit3.height + 'px',marginTop:formEdit3.marginTop + 'px',marginBottom:formEdit3.marginBottom + 'px'}" /> -->
              <a-button v-else size="large" show-word-limit class="btn" :style="{width:'90%',marginLeft:'5%',color:'#fff',height:formEdit3.height + 'px',marginTop:formEdit3.marginTop + 'px',marginBottom:formEdit3.marginBottom + 'px',fontSize:'25px',border:'none',backgroundImage:`linear-gradient(to right,${formEdit3.color1},${formEdit3.color2})`}">
                <!-- <img src="@/assets/images/first/servucePhone.png" style="width:10%;position:absolute;left:18%;top:23%;height:80%" /> -->
                {{ formAlertText.context }}
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div id="msg"></div>
    </div>

    <!-- 编辑栏 -->
    <a-drawer v-model:visible="drawer" :closable="false" :mask="false" :width="600">
      <template #title>
        <div>
          <h1>{{title}}</h1>
        </div>
      </template>
      <a-spin v-if="loading" size="large" />
      <Common v-else :data="commonData" :menuId="num" @transfer="receive" />
      <p style="margin-top:35px">
        <a-popconfirm title="确认保存" ok-text="确认" cancel-text="取消" @confirm="save">
          <a-button type="primary">保存</a-button>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-button @click="loadAttribute">重置</a-button>
      </p>
    </a-drawer>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import Common from './common.vue'
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { getMenu, getattribute, saveEdit } from '@/api/marketing/modList'
import { message } from 'ant-design-vue'

export default {
  components: {
    UnorderedListOutlined,
    CloseOutlined,
    Common
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      title: '',
      headLabel: '',
      head: {
        imgUrl: '',
        height: '',
        marginTop: '',
        marginBottom: ''
      },
      routeUrl: '',
      positionTop: 281,
      templateId: router.currentRoute.value.params.templateId,
      pageId: router.currentRoute.value.params.id,
      routeLink: 'http://www.baidu.com',
      layout: {
        marginTop: '',
        marginBottom: '',
        height: ''
      },
      buttonStyle: 0,
      bottomBtnStatus: 0,
      num: 0,
      radio: 100,
      prompt: 0,
      menuStatus: false,
      formAlert: false,
      drawer: false,
      background: null,
      loading: false,
      promptList: [],
      modMenu: [],
      albumList: [],
      bannerList: [],
      previewImg: [],
      commonData: [],
      banner: {
        interval: 2000,
        height: '',
        marginTop: '',
        marginBottom: ''
      },
      arrow: {
        url: '',
        jump: '',
        width: '',
        height: '',
        marginTop: '',
        marginBottom: ''
      },
      telStyle: {
        context: '',
        tel: ''
      },
      formAlertText: {
        context: ''
      },
      bottomBtn: {
        inquiryText: '',
        phoneText: '',
        phone: ''
      },
      formEdit: {
        type: 0,
        url: '',
        color1: '',
        color2: '',
        btnText: '',
        marginTop: '',
        marginBottom: '',
        height: ''
      },
      formEdit2: {
        type: 0,
        url: '',
        color1: '',
        color2: '',
        btnText: '',
        marginTop: '',
        marginBottom: '',
        height: ''
      },
      formEdit3: {
        type: 0,
        url: '',
        color1: '',
        color2: '',
        btnText: '',
        marginTop: '',
        marginBottom: '',
        height: ''
      },
      img1: {
        url: '',
        jump: '',
        height: '',
        display: '',
        radio: '',
        marginTop: '',
        marginBottom: ''
      },
      img2: {
        url: '',
        jump: '',
        height: '',
        display: '',
        radio: '',
        marginTop: '',
        marginBottom: ''
      },
      img3: {
        url: '',
        jump: '',
        height: '',
        display: '',
        radio: '',
        marginTop: '',
        marginBottom: ''
      },
      img4: {
        url: '',
        jump: '',
        height: '',
        display: '',
        radio: '',
        marginTop: '',
        marginBottom: ''
      },
      img5: {
        url: '',
        jump: '',
        height: '',
        display: '',
        radio: '',
        marginTop: '',
        marginBottom: ''
      },
      inquiryButton: {
        context: '',
        display: ''
      },
      phoneButton: {
        context: '',
        display: ''
      },
      form: {
        startAddress: '1',
        endAddress: '',
        phone: '',
        verification: ''
      }
    })
    onMounted(() => {
      loadData()
      for (let i = 0; i < 3; i++) {
        state.promptList.push('xx时xx分 xx差价成功')
      }
      setInterval(() => { // 28.8
        state.prompt -= 2
        if (state.prompt <= state.promptList.length * -32) state.prompt = 16
      }, 100)
    })
    const loadData = () => {
      getMenu({ templateId: state.templateId }).then(res => {
        if (res.code === 10000) {
          state.modMenu = res.data
        }
      }).catch(err => console.log(err))
      getattribute({ pageId: state.pageId, type: 0 })
        .then(res => {
          if (res.code === 10000) {
            state.commonData = res.data
            state.commonData.forEach(item => {
              switch (item.menuId) {
                case 3:
                  if (item.attriKey === 'headImgObjects') {
                    state.head.imgUrl = item.value.url
                    if (item.value.jump !== '' || item.value.jump !== null) state.head.jump = item.value.jump
                  }
                  if (item.attriKey === 'headImgHeight') state.head.height = item.value
                  if (item.attriKey === 'headImgMTop') state.head.marginTop = item.value
                  if (item.attriKey === 'headImgMBottom') state.head.marginBottom = item.value
                  break
                case 4:
                  if (item.attriKey === 'bannerImgS') state.banner.interval = Number(item.value)
                  if (item.attriKey === 'bannerImgHeight') state.banner.height = item.value
                  if (item.attriKey === 'bannerImgMTop') state.banner.marginTop = item.value
                  if (item.attriKey === 'bannerImgMBottom') state.banner.marginBottom = item.value
                  if (item.attriKey === 'bannerImgList') {
                    state.bannerList = item.value
                    state.bannerList.forEach(item => {
                      if (item.url === '' || item.url === 'www') item.url = require('@/assets/images/nullImg.jpg')
                    })
                  }
                  break
                case 5:
                  if (item.attriKey === 'arrowObjects') {
                    state.arrow.url = item.value.url
                    state.arrow.jump = item.value.jump
                    const img = new Image()
                    img.src = state.arrow.url
                    img.onload = function () {
                      const width = img.naturalWidth
                      state.arrow.width = width
                    }
                  }
                  if (item.attriKey === 'arrowHeight') state.arrow.height = item.value
                  if (item.attriKey === 'arrowMTop') state.arrow.marginTop = item.value
                  if (item.attriKey === 'arrowMBottom') state.arrow.marginBottom = item.value
                  break
                case 6:
                  if (item.attriKey === 'formButtonBackground') {
                    state.formEdit.type = item.value.type
                    state.formEdit.color1 = item.value.color1
                    state.formEdit.color2 = item.value.color2
                    state.formEdit.url = item.value.url
                  }
                  if (item.attriKey === 'formButtonText') state.formEdit.btnText = item.value
                  if (item.attriKey === 'formButtonHeight') state.formEdit.height = item.value
                  if (item.attriKey === 'formButtonMTop') state.formEdit.marginTop = item.value
                  if (item.attriKey === 'formButtonMBottom') state.formEdit.marginBottom = item.value
                  break
                case 7:
                  if (item.attriKey === 'customerServiceButtonText') state.telStyle.context = item.value
                  if (item.attriKey === 'customerServiceButtonMobile') state.telStyle.tel = item.value
                  if (item.attriKey === 'customerServiceButtonHeight') state.formEdit2.height = item.value
                  if (item.attriKey === 'customerServiceButtonMTop') state.formEdit2.marginTop = item.value
                  if (item.attriKey === 'customerServiceButtonMBottom') state.formEdit2.marginBottom = item.value
                  if (item.attriKey === 'customerServiceButtonBackground') {
                    state.formEdit2.type = item.value.type
                    state.formEdit2.color1 = item.value.color1
                    state.formEdit2.color2 = item.value.color2
                    state.formEdit2.url = item.value.url
                  }
                  break
                case 8:
                  if (item.attriKey === 'img1Objects') {
                    state.img1.url = item.value.url
                    state.img1.jump = item.value.jump
                  }
                  if (item.attriKey === 'img1Height') state.img1.height = item.value
                  if (item.attriKey === 'img1MTop') state.img1.marginTop = item.value
                  if (item.attriKey === 'img1MBottom') state.img1.marginBottom = item.value
                  if (item.attriKey === 'img1Display') state.img1.radio = item.value
                  break
                case 9:
                  if (item.attriKey === 'img2Objects') {
                    state.img2.url = item.value.url
                    state.img2.jump = item.value.jump
                  }
                  if (item.attriKey === 'img2Height') state.img2.height = item.value
                  if (item.attriKey === 'img2MTop') state.img2.marginTop = item.value
                  if (item.attriKey === 'img2MBottom') state.img2.marginBottom = item.value
                  if (item.attriKey === 'img2Display') state.img2.radio = item.value
                  break
                case 10:
                  if (item.attriKey === 'img3Objects') {
                    state.img3.url = item.value.url
                    state.img3.jump = item.value.jump
                  }
                  if (item.attriKey === 'img3Height') state.img3.height = item.value
                  if (item.attriKey === 'img3MTop') state.img3.marginTop = item.value
                  if (item.attriKey === 'img3MBottom') state.img3.marginBottom = item.value
                  if (item.attriKey === 'img3Display') state.img3.radio = item.value
                  break
                case 11:
                  if (item.attriKey === 'img4Objects') {
                    state.img4.url = item.value.url
                    state.img4.jump = item.value.jump
                  }
                  if (item.attriKey === 'img4Height') state.img4.height = item.value
                  if (item.attriKey === 'img4MTop') state.img4.marginTop = item.value
                  if (item.attriKey === 'img4MBottom') state.img4.marginBottom = item.value
                  if (item.attriKey === 'img4Display') state.img4.radio = item.value
                  break
                case 12:
                  if (item.attriKey === 'img5Objects') {
                    state.img5.url = item.value.url
                    state.img5.jump = item.value.jump
                  }
                  if (item.attriKey === 'img5Height') state.img5.height = item.value
                  if (item.attriKey === 'img5MTop') state.img5.marginTop = item.value
                  if (item.attriKey === 'img5MBottom') state.img5.marginBottom = item.value
                  if (item.attriKey === 'img5Display') state.img5.radio = item.value
                  break
                case 13:
                  if (item.attriKey === 'onLineText') state.inquiryButton.context = item.value
                  if (item.attriKey === 'onLineHide') state.inquiryButton.radio = item.value
                  break
                case 14:
                  if (item.attriKey === 'mobileText') state.phoneButton.context = item.value
                  if (item.attriKey === 'mobileHide') state.phoneButton.radio = item.value
                  break
                case 15:
                  if (item.attriKey === 'inquiryButtonTexe') state.formAlertText.context = item.value
                  if (item.attriKey === 'inquiryButtonHeight') state.formEdit3.height = item.value
                  if (item.attriKey === 'inquiryButtonMTop') state.formEdit3.marginTop = item.value
                  if (item.attriKey === 'inquiryButtonMBottom') state.formEdit3.marginBottom = item.value
                  if (item.attriKey === 'inquiryButtonBackground') {
                    state.formEdit3.type = item.value.type
                    state.formEdit3.color1 = item.value.color1
                    state.formEdit3.color2 = item.value.color2
                    state.formEdit3.url = item.value.url
                  }
                  break
              }
            })
            state.loading = false
          }
        }).catch(err => console.log(err))
    }
    const loadAttribute = () => {
      state.loading = true
      getattribute({ pageId: state.pageId, menuId: state.num, type: 1 })
        .then(res => {
          if (res.code === 10000) {
            state.commonData = res.data
            state.loading = false
          }
        }).catch(err => console.log(err))
    }
    const choose = (item, index) => {
      if (state.menuStatus === false) {
        const div = document.createElement('div')
        document.querySelector('#' + item.menuDesc).appendChild(div)
        div.className = 'sign'
        state.menuStatus = true
        const sign = document.querySelector('.sign')
        setTimeout(() => {
          if (item.menuDesc !== 'arrowImg') document.querySelector('#' + item.menuDesc).style.position = 'relative'
          sign.style.width = '100%'
          sign.style.height = document.querySelector('#' + item.menuDesc).getBoundingClientRect().height + 'px'
          sign.style.position = 'absolute'
          sign.style.top = '0'
          sign.style.left = '0'
          sign.style.background = 'rgba(185, 45, 45, 0.5)'
          sign.style.zIndex = '2'
        }, 500)
        document.querySelector('.preview').scrollTop = 0
        document.querySelector('.preview').scrollTop = document.querySelector('#' + item.menuDesc).getBoundingClientRect().top - 108 - 100
        state.num = item.id
        loadAttribute()
        state.title = item.menuName
        state.drawer = true
        setTimeout(() => {
          document.querySelector('#' + item.menuDesc).removeChild(sign)
          state.menuStatus = false
        }, 1500)
      } else message.error('点击太频繁了')
    }

    // 接收组件数据
    const receive = e => {
      if (e) {
        e.forEach(item => {
          switch (item.menuId) {
            case 3:
              if (item.attriKey === 'headImgObjects') {
                state.head.imgUrl = item.value.url
                if (item.value.jump !== '' || item.value.jump !== null) state.head.jump = item.value.jump
              }
              if (item.attriKey === 'headImgHeight') state.head.height = item.value
              if (item.attriKey === 'headImgMTop') state.head.marginTop = item.value
              if (item.attriKey === 'headImgMBottom') state.head.marginBottom = item.value
              break
            case 4:
              if (item.attriKey === 'bannerImgS') state.banner.interval = Number(item.value)
              if (item.attriKey === 'bannerImgHeight') state.banner.height = item.value
              if (item.attriKey === 'bannerImgMTop') state.banner.marginTop = item.value
              if (item.attriKey === 'bannerImgMBottom') state.banner.marginBottom = item.value
              if (item.attriKey === 'bannerImgList') {
                state.bannerList = []
                setTimeout(() => {
                  state.bannerList = item.value
                  state.bannerList.forEach(item => {
                    if (item.url === '' || item.url === 'www') item.url = require('@/assets/images/nullImg.jpg')
                  })
                }, 100)
              }
              break
            case 5:
              if (item.attriKey === 'arrowObjects') {
                state.arrow.url = item.value.url
                state.arrow.jump = item.value.jump
                state.arrow.width = item.width
                delete item.width
              }
              if (item.attriKey === 'arrowHeight') {
                state.arrow.height = item.value
              }
              if (item.attriKey === 'arrowMTop') state.arrow.marginTop = item.value
              if (item.attriKey === 'arrowMBottom') state.arrow.marginBottom = item.value
              break
            case 6:
              if (item.attriKey === 'formButtonBackground') {
                state.formEdit.type = item.value.type
                state.formEdit.color1 = item.value.color1
                state.formEdit.color2 = item.value.color2
                state.formEdit.url = item.value.url
              }
              if (item.attriKey === 'formButtonText') state.formEdit.btnText = item.value
              if (item.attriKey === 'formButtonHeight') state.formEdit.height = item.value
              if (item.attriKey === 'formButtonMTop') state.formEdit.marginTop = item.value
              if (item.attriKey === 'formButtonMBottom') state.formEdit.marginBottom = item.value
              break
            case 7:
              if (item.attriKey === 'customerServiceButtonText') state.telStyle.context = item.value
              if (item.attriKey === 'customerServiceButtonMobile') state.telStyle.tel = item.value
              if (item.attriKey === 'customerServiceButtonHeight') state.formEdit2.height = item.value
              if (item.attriKey === 'customerServiceButtonMTop') state.formEdit2.marginTop = item.value
              if (item.attriKey === 'customerServiceButtonMBottom') state.formEdit2.marginBottom = item.value
              if (item.attriKey === 'customerServiceButtonBackground') {
                state.formEdit2.type = item.value.type
                state.formEdit2.color1 = item.value.color1
                state.formEdit2.color2 = item.value.color2
                state.formEdit2.url = item.value.url
              }
              break
            case 8:
              if (item.attriKey === 'img1Objects') {
                state.img1.url = item.value.url
                state.img1.jump = item.value.jump
              }
              if (item.attriKey === 'img1Height') state.img1.height = item.value
              if (item.attriKey === 'img1MTop') state.img1.marginTop = item.value
              if (item.attriKey === 'img1MBottom') state.img1.marginBottom = item.value
              if (item.attriKey === 'img1Display') state.img1.radio = item.value
              break
            case 9:
              if (item.attriKey === 'img2Objects') {
                state.img2.url = item.value.url
                state.img2.jump = item.value.jump
              }
              if (item.attriKey === 'img2Height') state.img2.height = item.value
              if (item.attriKey === 'img2MTop') state.img2.marginTop = item.value
              if (item.attriKey === 'img2MBottom') state.img2.marginBottom = item.value
              if (item.attriKey === 'img2Display') state.img2.radio = item.value
              break
            case 10:
              if (item.attriKey === 'img3Objects') {
                state.img3.url = item.value.url
                state.img3.jump = item.value.jump
              }
              if (item.attriKey === 'img3Height') state.img3.height = item.value
              if (item.attriKey === 'img3MTop') state.img3.marginTop = item.value
              if (item.attriKey === 'img3MBottom') state.img3.marginBottom = item.value
              if (item.attriKey === 'img3Display') state.img3.radio = item.value
              break
            case 11:
              if (item.attriKey === 'img4Objects') {
                state.img4.url = item.value.url
                state.img4.jump = item.value.jump
              }
              if (item.attriKey === 'img4Height') state.img4.height = item.value
              if (item.attriKey === 'img4MTop') state.img4.marginTop = item.value
              if (item.attriKey === 'img4MBottom') state.img4.marginBottom = item.value
              if (item.attriKey === 'img4Display') state.img4.radio = item.value
              break
            case 12:
              if (item.attriKey === 'img5Objects') {
                state.img5.url = item.value.url
                state.img5.jump = item.value.jump
              }
              if (item.attriKey === 'img5Height') state.img5.height = item.value
              if (item.attriKey === 'img5MTop') state.img5.marginTop = item.value
              if (item.attriKey === 'img5MBottom') state.img5.marginBottom = item.value
              if (item.attriKey === 'img5Display') state.img5.radio = item.value
              break
            case 13:
              if (item.attriKey === 'onLineText') state.inquiryButton.context = item.value
              if (item.attriKey === 'onLineHide') state.inquiryButton.radio = item.value
              break
            case 14:
              if (item.attriKey === 'mobileText') state.phoneButton.context = item.value
              if (item.attriKey === 'mobileHide') state.phoneButton.radio = item.value
              break
            case 15:
              if (item.attriKey === 'inquiryButtonTexe') state.formAlertText.context = item.value
              if (item.attriKey === 'inquiryButtonHeight') state.formEdit3.height = item.value
              if (item.attriKey === 'inquiryButtonMTop') state.formEdit3.marginTop = item.value
              if (item.attriKey === 'inquiryButtonMBottom') state.formEdit3.marginBottom = item.value
              if (item.attriKey === 'inquiryButtonBackground') {
                state.formEdit3.type = item.value.type
                state.formEdit3.color1 = item.value.color1
                state.formEdit3.color2 = item.value.color2
                state.formEdit3.url = item.value.url
              }
              break
          }
        })
      }
    }

    // 保存
    const save = () => {
      state.loading = true
      const pageId = state.commonData[0].pageId
      state.commonData.forEach(item => {
        if (item.type === 'img' || item.type === 'banner' || item.type === 'background') {
          if (typeof item.value === 'object') item.value = JSON.stringify(item.value)
        }
      })
      saveEdit({ attributeList: state.commonData, pageId: pageId }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadAttribute()
        }
      }).catch(err => console.log(err))
    }
    // 预览图在线查价
    const showAlert = () => {
      state.formAlert = true
      document.querySelector('.form-alert').style.top = document.querySelector('.preview').scrollTop + 'px'
    }
    return {
      ...toRefs(state),
      loadAttribute,
      showAlert,
      choose,
      receive,
      save
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
.ant-page-container {
  height: 100% !important;
  .ant-menu {
    padding: 10px 0;
    height: 100% !important;
  }
}
</style>
