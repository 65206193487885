<template>
  <div>
    <a-form-item label="名称">
      <a-input v-model:value="search.imgName" placeholder="请输入图片名称搜索" style="width:210px;margin-right:15px" />
      <a-button style="background:#5584FF;color:#fff" @click="searchData" :loading="loadingAlbum">搜索</a-button>
      <a-upload v-model:file-list="fileList" :multiple="false" name="avatar" @change="handleChange" :show-upload-list="false" :before-upload="beforeUpload">
        <a-button style="margin-left:20px;background:#5584FF;color:#fff">
          本地上传
        </a-button>
      </a-upload>
    </a-form-item>
    <div class="album-box">
      <div v-show="loadingAlbum">
        <a-spin size="large" />
      </div>
      <div v-show="!loadingAlbum" class="album-img" v-for="(item,index) in albumList" :key="index">
        <img :width="150" :height="150" :src="item.imgUrl" />
        <div>
          <div style="width:150px;overflow: hidden;">名称:<span style="color:#1890FF">{{item.photoName}}</span></div>
          <div>操作人:<span style="color:#f00">{{item.operator}}</span></div>
        </div>
        <a-button style="width:100%" @click="choose(item)">选择</a-button>
      </div>
    </div>
    <a-pagination size="small" :total="pagination.total" :show-total="total => `共 ${pagination.total} 条`" @change="paginationChange" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getTemplateImg } from '@/api/marketing/modList'
import { fileUploadPublic as fileUpload } from '@/api/global'
export default {
  props: {
    status: Object
  },
  setup (props, context) {
    const state = reactive({
      loadingAlbum: false,
      albumList: [],
      fileList: [],
      data: {
        imgUrl: '',
        height: null,
        width: null,
        album: true,
        destroyOnClose: false
      },
      pagination: {
        current: 1
      },
      search: {
        imgName: ''
      }
    })
    // 获取相册图片列表
    const loadAlbum = () => {
      state.loadingAlbum = true
      getTemplateImg({
        current: state.pagination.current,
        photoName: state.search.imgName
      }).then(res => {
        if (res.code === 10000) {
          state.albumList = res.data.records
          state.pagination.current = res.data.current
          state.pagination.total = res.data.total
          state.pagination.size = res.data.size
          state.loadingAlbum = false
          // state.data.destroyOnClose = false
          // context.emit('imgUrl', state.data)
        }
      }).catch(err => { console.log(err) })
    }
    const getBase64 = (img, callback) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    }
    const beforeUpload = file => {
      state.fileList.push(file)
      return false
    }
    const handleChange = async info => {
      const formData = new FormData()
      formData.append('file', state.fileList[0])
      await getBase64(state.fileList[0], baseUrl => {
        const img = new Image()
        img.src = baseUrl
        img.onload = function () {
          const width = img.naturalWidth
          const da = width / 373
          state.data.width = width
          if (width >= 373) state.data.height = Math.round(img.naturalHeight / da)
          fileUploadImg(formData)
        }
        state.fileList = []
      })
    }
    const fileUploadImg = (formData, index) => {
      fileUpload(formData).then(res => {
        if (res.code === 10000) {
          if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
            state.data.imgUrl = res.data.url.split('?')[0]
            state.data.destroyOnClose = true
            state.data.album = false
            context.emit('imgUrl', state.data)
          }
        }
      })
    }
    onMounted(() => {
      loadAlbum()
    })
    const searchData = () => {
      state.pagination.current = 1
      loadAlbum()
    }
    const choose = item => {
      state.data.imgUrl = item.imgUrl
      state.data.album = false
      const img = new Image()
      img.src = item.imgUrl
      img.onload = function () {
        const width = img.naturalWidth
        const da = width / 373
        state.data.width = width
        if (width >= 373) state.data.height = Math.round(img.naturalHeight / da)
        else state.data.height = img.naturalHeight
        context.emit('imgUrl', state.data)
      }
    }
    const paginationChange = e => {
      state.pagination.current = e
      loadAlbum()
    }

    return {
      ...toRefs(state),
      paginationChange,
      beforeUpload,
      handleChange,
      searchData,
      choose
    }
  }
}
</script>

<style lang="less" scoped>
.album-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .album-img {
    height: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
  }
}
</style>
