<template>
  <div style="margin-bottom:20px">
    <h2 class="edit-title">按钮样式</h2>
    <a-radio-group name="radioGroup" v-model:value="dataObj.type">
      <a-radio :value="0">按钮颜色</a-radio>
      <a-radio :value="1">背景图片</a-radio>
    </a-radio-group>
    <div v-show="dataObj.type === 0">
      <input type="color" :value="dataObj.color1" id="startColor" style="width:30%;margin-right:20px" @change="startChange" />
      <input type="color" :value="dataObj.color2" id="endColor" style="width:30%" @change="endChange" />
    </div>
    <div v-show="dataObj.type === 1">
      <span style="color:#b7b7b7">请上传宽度未355px或710px的图片,支持jpg/png/gif格式,大小不超过2M</span>
      <div style="display:flex;align-items:center">
        <a-image :width="100" :src="dataObj.url" />
        <div style="margin-left:15px">
          <a-button @click="album = true">
            选择图片
          </a-button>
          <!-- <a-upload v-model:file-list="fileList" :multiple="false" :show-upload-list="false" @change="handleChange" :before-upload="beforeUpload">
            <a-button>
              <upload-outlined></upload-outlined>
              选择图片
            </a-button>
          </a-upload> -->
        </div>
      </div>
    </div>
    <a-modal v-model:visible="album" :width="850" footer="" title="相册" :destroyOnClose="destroyOnClose">
      <Album @imgUrl="receive" />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { fileUploadPublic as fileUpload } from '@/api/global'
import Album from './album'
import { message } from 'ant-design-vue'
export default {
  props: {
    data: Object
  },
  components: {
    Album
  },
  setup (props, context) {
    const state = reactive({
      album: false,
      destroyOnClose: false,
      dataObj: {},
      fileList: []
    })
    onMounted(() => {
      state.dataObj = props.data
      // console.log(state.dataObj)
    })
    // 图片上传四件套 ↓
    const getBase64 = (img, callback) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    }
    const beforeUpload = file => {
      state.fileList.push(file)
      return false
    }
    const handleChange = info => {
      if (state.menuId !== 4) {
        const formData = new FormData()
        formData.append('file', state.fileList[0])
        getBase64(state.fileList[0], baseUrl => {
          if (typeof state.dataObj === 'object') state.dataObj.url = baseUrl
        })
        fileUploadImg(formData)
      }
    }
    const fileUploadImg = (formData, index) => {
      fileUpload(formData).then(res => {
        if (res.code === 10000) {
          if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
            state.dataObj.url = res.data.url.split('?')[0]
            context.emit('data', state.dataObj)
            state.fileList = []
            message.success(res.msg)
          }
        }
      })
    }
    // 四件套 ↑
    // 按钮颜色选择
    const startChange = e => {
      const color = document.querySelector('#startColor')
      state.dataObj.color1 = color.value
      context.emit('data', state.dataObj)
    }
    const endChange = e => {
      const color = document.querySelector('#endColor')
      state.dataObj.color2 = color.value
      context.emit('data', state.dataObj)
    }
    // 接收相册传值
    const receive = e => {
      // console.log(e)
      if (e) {
        if (e.imgUrl !== '') state.dataObj.url = e.imgUrl
        state.album = e.album
        state.destroyOnClose = e.destroyOnClose
        context.emit('data', state.dataObj)
      }
    }

    return {
      ...toRefs(state),
      startChange,
      receive,
      endChange,
      beforeUpload,
      handleChange
    }
  }
}
</script>

<style lang="less" scoped>
</style>
