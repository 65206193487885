<template>
  <div>
    <h2 class="edit-title" style="margin-bottom:0">上传图片</h2>
    <p style="color:#b7b7b7">请上传宽度未375px或750px的图片,支持jpg/png/gif格式,大小不超过2M,如需增加轮播图片,请点击下面的添加按钮增加新图片,最多支持5张
    </p>
    <div style="display:flex;background:#F8F9FB;margin-bottom:15px" v-for="(item,index) in data" :key="item.id" @mousedown="onClick(index)">
      <a-image :width="100" style="height:92.8px" :src="item.url" />
      <div style="display:flex;flex-direction: column;justify-content: space-around;margin-left:15px;position: relative">
        <a-button style="width:25%" @click="album = true">
          选择图片
        </a-button>
        <a-input v-model:value="item.jump" />
        <span style="color:#758bf3">注：如需点击图片跳转，请在此输入所需要跳转的url地址</span>
        <a-popconfirm title="确定要删除我吗?" ok-text="确定" cancel-text="取消" @confirm="deleteBanner(index)">
          <DeleteOutlined class="delete-btn" />
        </a-popconfirm>
      </div>
    </div>
    <p style="text-align: center;margin:10px 0;cursor:pointer;background:#F8F9FB" @click="addBanner">+ 添加 {{data.length}}/5</p>
    <a-modal v-model:visible="album" :width="850" footer="" title="相册" :destroyOnClose="destroyOnClose">
      <Album @imgUrl="receive" />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import Album from './album'
export default {
  components: {
    DeleteOutlined,
    Album
  },
  props: { data: Array },
  setup (props, context) {
    const state = reactive({
      index: 0,
      destroyOnClose: false,
      album: false,
      fileList: [],
      data: props.data
    })
    onMounted(() => {
      state.data.forEach(item => {
        if (item.url === 'www' || item.url === '') item.url = require('@/assets/images/nullImg.jpg')
      })
    })
    const onClick = index => {
      state.index = index
    }
    // banner 添加
    const addBanner = () => {
      if (state.data.length < 5) {
        state.data.push({
          url: '',
          jump: ''
        })
      } else message.error('不能超过5张')
    }
    // 删除banner
    const deleteBanner = index => {
      state.data.splice(index, 1)
    }
    // 接收相册传值
    const receive = e => {
      // console.log(e)
      if (e) {
        if (e.imgUrl !== '') state.data[state.index].url = e.imgUrl
        state.album = e.album
        state.destroyOnClose = e.destroyOnClose
        context.emit('data', state.data)
      }
    }
    return {
      ...toRefs(state),
      onClick,
      receive,
      addBanner,
      deleteBanner
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
