<template>
  <div>
    <h2 class="edit-title" style="margin-bottom:0">上传图片</h2>
    <span style="color:#b7b7b7">请上传宽度为109*76px或218*152px的图片,支持jpg/png/gif格式,大小不超过2M</span>
    <div style="display:flex;background:#F8F9FB;margin-bottom:15px">
      <img :width="100" :height="100" :src="dataObj.value.url" />
      <div style="display:flex;flex-direction: column;justify-content: space-around;margin-left:15px;position: relative">
        <a-button style="width:25%" @click="album = true">
          选择图片
        </a-button>
        <a-input placeholder="跳转路径" v-model:value="dataObj.value.jump" style="width:100%;margin-top:15px" @change="jumpChange">
          <template #suffix>
            <LinkOutlined />
          </template>
        </a-input>
        <span style="color:#758bf3">注：如需点击图片跳转，请在此输入所需要跳转的url地址</span>
      </div>
    </div>
    <a-modal v-model:visible="album" :width="850" footer="" title="相册" :destroyOnClose="destroyOnClose">
      <Album @imgUrl="receive" />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import Album from './album'

export default {
  props: {
    data: Object
  },
  components: {
    Album
  },
  setup (props, context) {
    const state = reactive({
      index: null,
      fileList: [],
      album: false,
      destroyOnClose: false,
      dataObj: props.data
    })
    onMounted(() => {
      state.dataObjObj = props.data
      state.dataObjObj.height = ''
      if (state.dataObj.value.url === '' || state.dataObj.value.url === 'www') state.dataObj.value.url = require('@/assets/images/nullImg.jpg')
    })

    const receive = e => {
      if (e) {
        if (e.imgUrl !== '') state.dataObj.value.url = e.imgUrl
        state.album = e.album
        state.dataObj.height = e.height
        state.dataObj.width = e.width
        state.destroyOnClose = e.destroyOnClose
        context.emit('data', state.dataObj)
      }
    }
    return {
      ...toRefs(state),
      receive
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
.album-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .album-img {
    height: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
  }
}
</style>
