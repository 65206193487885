<template>
  <div style="position:relative">
    <a-button @click="preview" class="preview-btn">同步预览</a-button>
    <div v-for="item in  commonData" :key="item.id">
      <div v-show="item.type==='input'">
        {{item.label + '：'}}
        <a-input v-model:value="item.value" :type="item.label.includes('高') || item.label.includes('距') || item.label.includes('电话') ? 'number' : 'string' " style="width:200px;margin-bottom:10px" />
        <span v-show="item.label === '高度'" style="color:#f00"> (必填)</span>
      </div>
      <div v-show="item.type === 'radio'">
        是否隐藏：
        <a-radio-group v-model:value="item.value">
          <a-radio value="none">是</a-radio>
          <a-radio value="inline">否</a-radio>
        </a-radio-group>
      </div>
      <div v-show="item.type === 'text'">
        <a-textarea v-model:value="item.value" placeholder="头部代码" :rows="4" />
      </div>
      <div v-show="item.type === 'img'">
        <UploadImg :data="item" @data="uploadChange" />
      </div>
      <div v-if="item.type === 'banner'">
        <Popconfirm :data="item.value" @data="uploadChange" />
      </div>
      <div v-if="item.type === 'background'">
        <ButtonStyle :data="item.value" @data="uploadChange" />
      </div>
    </div>
  </div>

</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import UploadImg from './common/uploadImg.vue'
import Popconfirm from './common/popconfirm.vue'
import ButtonStyle from './common/buttonStyle.vue'

export default {
  components: {
    UploadImg,
    Popconfirm,
    ButtonStyle
  },
  props: {
    menuId: Number,
    data: Array
  },
  setup (props, context) {
    const state = reactive({
      buttonStyle: 0,
      index: 0,
      menuId: 0,
      status: false,
      banner: false,
      button: false,
      fileList: [],
      commonData: [],
      bannerList: [],
      formEdit: {
        btnText: '按钮文案',
        color1: '',
        color2: ''
      }
    })
    const loadData = () => {
      state.commonData = props.data
      state.menuId = props.menuId
      state.commonData.forEach(item => {
        if (item.attriKey === 'bannerImgList') {
          state.bannerList = item.value
          state.banner = true
        }
        if (item.attriKey === 'formButtonBackground') {
          state.buttonStyle = item.value.type
          state.formEdit.color1 = item.value.color1
          state.formEdit.color2 = item.value.color2
          state.formEdit.url = item.value.url
          state.button = true
        }
      })
    }
    const jumpChange = (val, res) => {
      // console.log(val.data, res)
    }
    onMounted(() => {
      loadData()
      state.commonData.forEach(item => {
        if (item.type === 'img') state.status = true
      })
    })
    // 接收图片上传组件传值
    const uploadChange = e => {
      if (e) {
        state.commonData.forEach(item => {
          if (item.type === 'img') item = e
          if (item.type === 'background') item.value = e
          if (item.label === '高度' && item.type === 'input') {
            if (e.height) {
              item.value = e.height
            }
          }

          if (item.type === 'bannerImgList') {
            setTimeout(() => {
              item.value = e
            }, 300)
          }
        })
      }
    }
    const preview = () => {
      context.emit('transfer', state.commonData)
      // console.log(state.commonData)
    }

    return {
      ...toRefs(state),
      jumpChange,
      preview,
      uploadChange,
      loadData
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
